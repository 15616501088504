import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { WebcamModule } from 'ngx-webcam';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import { FoundationService } from './_shared/foundation.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#fb7575",
  "fgsPosition": "center-center",
  "fgsSize": 100,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255,255,255,0.8)",
  "pbColor": "#fb7575",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    WebcamModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [{
    provide: LocationStrategy, useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
